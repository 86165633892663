<template>
  <div>
    <img src="../../assets/index/logo-seo.png" alt="让茶官网-高山好茶喝让茶" srcset="" />


    <div class="title">让茶品牌介绍</div>

    <div class="title">品牌简介</div>
    <div class="content">
      让茶，是专注无糖茶饮料的快消品品牌，“高山好茶，喝让茶”。让茶致力于为全球消费者提供方便好喝的茶饮料产品，旗下拥有高山花茶系列、高山纯茶系列、草本茶系列、无糖果味茶系列。
    </div>
    <div class="title">品牌愿景</div>
    <div class="content">让茶陪你每一天。</div>
    <div class="title">品牌使命</div>
    <div class="content">
      让种茶人小康，让喝茶人健康。
    </div>
    <div class="title">品牌释义</div>
    <div class="content">
      “让茶”即“请你来喝茶”。
以“茶”为载体，让中国传统礼仪文化以及精致茶饮生活方式，在年轻族群中，焕发现代风貌。

    </div>
    <div class="title">产品理念</div>
    <div class="content">自然、健康、方便、好喝</div>
    <div class="title">让茶产品</div>
    <div class="content">
      旗下拥有高山花茶系列、高山纯茶系列、草本茶系列、无糖果味茶系列。
    </div>

    <div class="title">高山花茶系列：自然清新茶味自然</div> 
    <div class="content">高山花茶系列-茉莉乌龙茶</div>
    <div class="content">高山花茶系列-竹香乌龙茶</div>
    <div class="content">高山花茶系列-桂花乌龙茶</div>

    <div class="title">高山纯茶系列：喝好茶高山茶</div> 
    <div class="content">高山纯茶系列-高山绿茶</div>
    <div class="content">高山纯茶系列-高山乌龙茶</div>

    <div class="title">草本系列：清爽⽢甜少苦涩</div>
    <!-- <div class="content">
      系列简介： 甄选中国各地优质生态茶园，每一瓶都是100%原叶萃取，保留茶叶<br />自然鲜爽味道；选用赤藓糖醇代替蔗糖，0糖0脂0卡；果汁真实添加，巧妙融<br />合果香与茶香，纯享馥郁清香的茶味与自然缤纷的果味，随心畅饮真果茶
    </div> -->
    <div class="content">草本系列-茉莉花茶</div>
    <div class="content">草本茶系列-大麦清茶</div>

    <div class="title">无糖果味茶系列：0糖0能量 真茶真好喝</div>
    <div class="content">无糖果味茶系列-白桃青柠红茶</div>
    <div class="content">无糖果味茶系列-葡萄乌龙茶</div>
    <div class="content">无糖果味茶系列-青梅龙井茶</div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style scoped lang="less">
.title {
  /*  text-align: center; */
  font-weight: bold;
  font-size: 24px;
  margin-top: 24px;
}
.content {
  /* text-align: center; */
  font-size: 18px;
  margin: 12px 0;
}
</style>
